import Flatpickr from 'stimulus-flatpickr';
import { requestSubmit } from '../../lib/request_submit';
import { merge, omitBy, isUndefined, isNull } from 'lodash-es'

export default class extends Flatpickr {
  static targets = ['instance', 'clear'];

  connect() {
    this.config = this.instanceDateOptions();

    if (this.hasClearTarget) {
      this.instanceTarget.addEventListener('change', this.updateClearButtonVisibility.bind(this));
      this.updateClearButtonVisibility();
    }

    super.connect();
  }

  instanceDateOptions() {
    // flatpickr options derived from the input see DatepickerInput.flatpickr_data
    return merge(this.defaultDateOptions(), this.customDateOptions(), {
      onClose: (_dates, dateStr, instance) => {
        instance.element.value = dateStr;

        if (this.instanceTarget.dataset.submitOnClose === 'true') {
          requestSubmit(instance.element.form);
        }
      },
    });
  }

  customDateOptions() {
    return omitBy({
      minDate: this.instanceTarget.dataset.flatpickrMinDate,
      maxDate: this.instanceTarget.dataset.flatpickrMaxDate,
      enableTime: this.instanceTarget.dataset.flatpickrEnableTime,
      mode: this.instanceTarget.dataset.flatpickrMode,
      altFormat: this.instanceTarget.dataset.flatpickrAltFormat,
    }, this.isBlankOption)
  }

  defaultDateOptions() {
    return {
      altInput: true,
      altFormat: 'l, F J Y',
      time_24hr: true,
    };
  }

  isBlankOption(val) {
    if (isUndefined(val) || isNull(val)) {
      return true
    }

    return val.toString().length == 0
  }

  updateClearButtonVisibility() {
    if (this.instanceTarget.value) {
      this.clearTarget.classList.remove('hide');
    } else {
      this.clearTarget.classList.add('hide');
    }
  }

  clearTargetClicked() {
    this.instanceTarget._flatpickr.clear();
  }
}
