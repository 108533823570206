import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // We were getting occassional exceptions from ActionText entries having been saved
  // with attachments that had a blob: URL.  This is a temporary value that Trix inserts
  // while an async upload is performed.  If the form is submitted while the upload is
  // in progress then the temp value is persisted forever.  This controller watches
  // all Trix editors, and when an upload is in progress, disables the submit button.
  // As a failsafe, it also listens for the submit event on all forms and prevents submission
  // if it contains a Trix area with an upload pending.

  connect() {
    this.element.addEventListener('trix-change', this.disableSubmitIfTrixAttachmentsUploading.bind(this))

    const { hasTrixAttachmentsUploading, trixElements } = this

    document.querySelectorAll('form').forEach(form => {
      form.addEventListener('submit', (e) => {
        if (hasTrixAttachmentsUploading(trixElements(form))) {
          alert('Please wait for the attachments to finish uploading.')
          e.preventDefault()
          e.stopPropagation()
          Rails.enableElement(form.querySelector('input[type="submit"]'))
        }
      })
    })
  }

  disableSubmitIfTrixAttachmentsUploading() {
    const { hasTrixAttachmentsUploading, trixElements } = this

    document.querySelectorAll('form').forEach(form => {
      const trix = trixElements(form)

      if (trix.length === 0) {
        return
      }

      const pending = hasTrixAttachmentsUploading(trix)

      form.querySelectorAll('input[type="submit"]').forEach(submit => {
        submit.disabled = pending
      })
    })
  }

  hasTrixAttachmentsUploading(trixElements) {
    return trixElements
      .flatMap(trix => trix.editor.getDocument().getAttachments())
      .some(attachment => attachment.isPending())
  }

  trixElements(form) {
    return Array.from(form.querySelectorAll("trix-editor"))
  }
}
