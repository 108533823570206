import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.defaultDirection = this.element.dataset.tooltipDirection || "top";
    this.updatePlacement = this.updatePlacement.bind(this);
    window.addEventListener("resize", this.updatePlacement);
  }

  disconnect() {
    window.removeEventListener("resize", this.updatePlacement);
  }

  show() {
    this.contentTarget.classList.add("visible");
    this.updatePlacement();
  }

  hide() {
    this.contentTarget.classList.remove("visible");
  }

  updatePlacement() {
    const tooltip = this.contentTarget;
    const tipGap = 10;
    const elementRect = this.element.getBoundingClientRect();
    const tooltipRect = tooltip.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Reset tooltip classes and styles
    tooltip.classList.remove("stimulus-tooltip-top", "stimulus-tooltip-bottom", "stimulus-tooltip-left", "stimulus-tooltip-right");
    tooltip.style.top = tooltip.style.left = "";

    const fitsHorizontally =
      elementRect.left + elementRect.width / 2 - tooltipRect.width / 2 >= 0 &&
      elementRect.right - elementRect.width / 2 + tooltipRect.width / 2 <= viewportWidth;

    const directions = {
      top: {
        condition: elementRect.top > tooltipRect.height + tipGap && fitsHorizontally,
        apply: () => {
          tooltip.style.top = `-${tooltipRect.height + tipGap}px`;
          tooltip.style.left = `${elementRect.width / 2 - tooltipRect.width / 2}px`;
          tooltip.classList.add("stimulus-tooltip-top");
        },
      },
      bottom: {
        condition: elementRect.bottom + tooltipRect.height + tipGap < viewportHeight && fitsHorizontally,
        apply: () => {
          tooltip.style.top = `${elementRect.height + tipGap}px`;
          tooltip.style.left = `${elementRect.width / 2 - tooltipRect.width / 2}px`;
          tooltip.classList.add("stimulus-tooltip-bottom");
        },
      },
      right: {
        condition:
          elementRect.right + tooltipRect.width + tipGap < viewportWidth &&
          elementRect.top + elementRect.height / 2 - tooltipRect.height / 2 >= 0 &&
          elementRect.bottom - elementRect.height / 2 + tooltipRect.height / 2 <= viewportHeight,
        apply: () => {
          tooltip.style.top = `${elementRect.height / 2 - tooltipRect.height / 2}px`;
          tooltip.style.left = `${elementRect.width + tipGap}px`;
          tooltip.classList.add("stimulus-tooltip-right");
        },
      },
      left: {
        condition:
          elementRect.left > tooltipRect.width + tipGap &&
          elementRect.top + elementRect.height / 2 - tooltipRect.height / 2 >= 0 &&
          elementRect.bottom - elementRect.height / 2 + tooltipRect.height / 2 <= viewportHeight,
        apply: () => {
          tooltip.style.top = `${elementRect.height / 2 - tooltipRect.height / 2}px`;
          tooltip.style.left = `-${tooltipRect.width + tipGap}px`;
          tooltip.classList.add("stimulus-tooltip-left");
        },
      },
    };

    // Try default direction first
    if (directions[this.defaultDirection]?.condition) {
      directions[this.defaultDirection].apply();
      return;
    }

    // Fallback to other directions
    for (const [direction, { condition, apply }] of Object.entries(directions)) {
      if (condition) {
        apply();
        return;
      }
    }
  }
}
