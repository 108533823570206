import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fieldsContainer"]

  connect() {
    this.index = document.querySelectorAll('.external-links .field-wrapper').length
  }

  addFields(event) {
    event.preventDefault()
    this.index++
    const fieldWrapper = document.createElement('div')
    fieldWrapper.classList.add('field-wrapper')

    const titleField = this.createField(`item[external_links][][title]`, `URL Title ${this.index}`)
    const urlField = this.createField(`item[external_links][][link]`, `URL ${this.index}`)
    const removeButton = this.createRemoveButton()

    fieldWrapper.innerHTML = titleField + urlField + removeButton
    this.fieldsContainerTarget.appendChild(fieldWrapper)
  }

  createField(name, label) {
    return `
      <div class="field">
        <label for="${name}">${label}</label>
        <input type="text" name="${name}" id="${name}">
      </div>
    `
  }

  createRemoveButton() {
    return `
      <button type="button" data-action="click->sustainability--external-links#removeFields" class="remove-button">Remove</button>
    `
  }

  removeFields(event) {
    event.preventDefault()
    event.target.closest('.field-wrapper').remove()
  }
}
