import { FormRcController } from 'stimulus-library'
import { debounce } from 'lodash-es'

export default class extends FormRcController {

  connect() {
    // extend the stimulus libary version to include a debounce
    this.debounceSubmit = debounce(this.submit.bind(this), 200)
  }

}