import { Controller } from "@hotwired/stimulus"
import { escapeRegExp, get } from "lodash-es"

export default class extends Controller {
  static targets = [
    "actionForm",
    "statusInput",
    "ownerInput",
    "findUserInput",
    "scoreInput",
    "circlesWrapper",
    "circle"
  ]

  connect() {
    if (this.hasScoreInputTarget) {
      this.updateCircles(this.scoreInputTarget?.value || 0)
      this.circleTargets.forEach((circle, index) => {
        circle.addEventListener("mouseover", () => this.previewActiveCircles(index))
        circle.addEventListener("mouseleave", () => this.clearPreview())
      })
    }
  }

  previewActiveCircles(index) {
    this.circleTargets.forEach((circle, i) => {
      circle.classList.toggle("active", i <= index)
    })
  }

  clearPreview() {
    const index = this.scoreInputTarget.value
    this.updateCircles(index)
  }


  setScore(event) {
    const index = this.circleTargets.indexOf(event.currentTarget) + 1
    this.scoreInputTarget.value = index
    this.updateCircles(index)
  }

  updateCircles(score) {
    this.circleTargets.forEach((circle, i) => {
      if (i < score) {
        circle.classList.add("active")  // Add your active styling class here
      } else {
        circle.classList.remove("active")
      }
    })
  }

  findUser(event) {
    const regex = new RegExp(".*\\b" + escapeRegExp(this.findUserInputTarget.value) + ".*", "gi")
    let userOptions = this.element.querySelectorAll(".user-option")
    let matchCount = 0
    if (this.findUserInputTarget.value.length > 0) {
      userOptions.forEach(function (elem, index) {
        if (elem.textContent.match(regex)) {
          elem.classList.remove("hide")
          matchCount += 1
        } else {
          elem.classList.add("hide")
        }
      })
    }
    if (matchCount == 0) {
      userOptions.forEach(function (elem, index) {
        elem.classList.add("hide")
      })
    }
  }

  setStatus(event) {
    let statusId = event.currentTarget.dataset.status
    if (this.statusInputTarget.value == statusId) {
      event.preventDefault()
      return false
    }

    this.statusInputTarget.value = statusId
    this.actionFormTarget.submit()
  }

  setOwner(event) {
    let ownerId = event.currentTarget.dataset.owner
    let assignPath = event.currentTarget.dataset.path
    this.ownerInputTarget.value = ownerId
    this.actionFormTarget.action = assignPath
    this.actionFormTarget.submit()
  }
}
