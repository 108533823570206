import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["destroyField", "checkboxField"]

  connect() {
    this.updateDestroy()
  }

  updateDestroy() {
    this.destroyFieldTarget.value = !this.checkboxFieldTarget.checked;
  }
}
