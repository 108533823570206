import { Controller } from '@hotwired/stimulus';

// Add data-hidden-field-sync-target="hiddenField" to the hidden field you wish to keep in sync with it's unhidden namesake
// When the unhidden field changes, it's hidden equivalent's value will be set to the new value

export default class extends Controller {
  static targets = ['hiddenField'];

  connect() {
    this.syncHiddenFields();
  }

  syncHiddenFields() {
    this.hiddenFieldTargets.forEach((hiddenField) => {
      const name = hiddenField.name;
      const fields = this.element.querySelectorAll(`input[name="${name}"], select[name="${name}"], textarea[name="${name}"]`);
      fields.forEach((field) => {
        if (field.type !== 'hidden') {
          field.addEventListener('change', (event) => {
            hiddenField.value = event.target.value;
          });
        }
      });
    });
  }
}

