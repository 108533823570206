import { Controller } from "@hotwired/stimulus"
import { filter, last } from "lodash-es"

export default class extends Controller {
  static targets = [
    "metricsEditor",
    "metricsContainer",
    "blankMetricTemplate",
    "numericFieldsTemplate",
    "optionsFieldsTemplate",
    "passfailFieldsTemplate",
    "sfg20ServiceFieldsTemplate",
    "defaultFieldsTemplate",
    "appendMetricsOption",
    "removeMetricsOption",
    "addMetricContainer",
    "maxMetricsMessage",
  ]

  toggleMetricsEditor(event) {
    if (!this.hasMetricsEditorTarget) {
      return false
    }

    if (event.target.checked) {
      this.metricsEditorTarget.classList.remove("hide")
    } else {
      this.metricsEditorTarget.classList.add("hide")
    }
  }

  addMetric(event) {
    event.preventDefault()
    event.stopPropagation()
    this.insertBlankMetric()
    if (this.numActiveMetrics() >= 5) {
      this.showMaxMetrics()
    }
  }

  removeMetric(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.classList.add("hide")
    if (this.numActiveMetrics() < 5) {
      this.hideMaxMetrics()
    }
  }

  insertBlankMetric() {
    const content = this.blankMetricTemplateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.metricsContainerTarget.insertAdjacentHTML("beforeend", content)
  }

  selectMetricType(event) {
    const metricType = event.target.value
    let template
    switch (metricType) {
      case "Survey::Task::Metric::Numeric":
        template = this.numericFieldsTemplateTarget
        break
      case "Survey::Task::Metric::Options":
        template = this.optionsFieldsTemplateTarget
        break
      case "Survey::Task::Metric::PassFail":
        template = this.passfailFieldsTemplateTarget
        break
      case "Survey::Task::Metric::SFG20Service":
        template = this.sfg20ServiceFieldsTemplateTarget
        break
      default:
        template = this.defaultFieldsTemplateTarget
    }

    let metricEl = event.target.closest(".new-metric")

    if (template) {
      let templateEl = template.content.cloneNode(true)
      const selectName = event.target.name
      const targetPrefix = selectName.replace("[type]", "")

      // propagate timestamped attribute ids to template inputs
      const regex = /survey_task\[metrics_attributes\]\[[0-9]+\](.*)/
      let match
      let attrName
      templateEl.querySelectorAll("input, select").forEach(function (input) {
        attrName = input.name
        match = attrName.match(regex)
        if (match) {
          attrName = match[1]
        }
        input.setAttribute("name", `${targetPrefix}${attrName}`)

        if (attrName == "[name]") {
          let inputToClone = metricEl.querySelector(`input[name='${targetPrefix}${attrName}']`)
          if (inputToClone && inputToClone.value != "") {
            input.value = inputToClone.value
          }
        }
      })

      let settingsEl = metricEl.querySelector(".metric-settings")
      settingsEl.innerHTML = ""
      settingsEl.appendChild(templateEl)
      settingsEl.classList.remove("hide")
    }
  }

  appendMetricsOption(event) {
    event.preventDefault()
    event.stopPropagation()

    let parentEl = event.target.closest(".options")
    let options = parentEl.querySelectorAll(".option-container")
    let newOptionEl = last(options).cloneNode(true)

    const error = newOptionEl.querySelector("small.error")
    if (error) {
      // remove previous error
      error.remove()
      newOptionEl.querySelector("input").classList.remove("error")
    }

    const input = newOptionEl.querySelector("input")
    // parse index from the (last) input - in case any have been removed
    const idx = parseInt(input.name.match(/.*\[([0-9]+)\]/)[1])
    input.value = ''
    input.setAttribute("name", input.name.replace(`[options][${idx}]`, `[options][${idx + 1}]`))
    input.setAttribute("id", input.id.replace(`_options_${idx}`, `_options_${idx + 1}`))
    const addOptionEl = parentEl.querySelector(".add-metric-option")
    parentEl.insertBefore(newOptionEl, addOptionEl)
  }

  removeMetricsOption(event) {
    event.preventDefault()
    event.stopPropagation()

    let parentEl = event.target.closest(".options")
    let targetRow = event.target.closest(".option-container")
    let targetInput = targetRow.querySelector("input")
    let numOptions = parentEl.querySelectorAll(".option-container input").length

    if (numOptions < 2) {
      targetRow.querySelector(".input").classList.add("error")
      const errorEl = document.createElement("small")
      errorEl.classList.add("error")
      errorEl.innerHTML = "You must leave at least one option"
      targetInput.after(errorEl)
      return
    }

    targetRow.remove()
  }

  showMaxMetrics() {
    this.addMetricContainerTarget.classList.add("hide")
    this.maxMetricsMessageTarget.classList.remove("hide")
  }

  hideMaxMetrics() {
    this.addMetricContainerTarget.classList.remove("hide")
    this.maxMetricsMessageTarget.classList.add("hide")
  }

  numActiveMetrics() {
    // number of metrics not marked for deletion
    const nodes = this.metricsContainerTarget.querySelectorAll(".task-metric input[name*='_destroy']")
    return filter(nodes, function (el) {
      return el.value != "1"
    }).length
  }

  toggleCustomUnits(event) {
    event.preventDefault()
    const commonUnitsContainer = event.currentTarget.parentNode.querySelector(".common-units")
    const commonSelect = commonUnitsContainer.querySelector("select")
    const customUnitsContainer = event.currentTarget.parentNode.querySelector(".custom-units")
    const customInput = customUnitsContainer.querySelector("input")
    commonUnitsContainer.querySelector("select").disabled = true

    commonSelect.classList.add("hide")
    customUnitsContainer.classList.remove("hide")
    if (commonSelect.value) {
      customInput.value = commonSelect.value
    }
    commonSelect.disabled = true
    customInput.disabled = false
    event.currentTarget.classList.add("hide")
  }
}
